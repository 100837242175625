import { cva } from 'class-variance-authority';

export const text = cva('', {
  variants: {
    variant: {
      p3: 'text-p3',
      p4: 'text-p4',
      p5: 'text-p5',
      p6: 'text-p6',
    },
    weight: {
      normal: 'font-normal',
      medium: 'font-medium',
      bold: 'font-bold',
    },
  },
  defaultVariants: {
    variant: 'p4',
  },
});
