import { VariantProps } from 'class-variance-authority';
import { Text, TextProps } from 'react-aria-components';
import { text } from '../../electrons/text';

export function _Text({
  children,
  variant,
  weight,
  className,
  innerRef,
  ...props
}: TextProps & VariantProps<typeof text> & { innerRef?: React.RefObject<HTMLElement> }) {
  return (
    <Text className={text({ variant, weight, className })} dir="auto" ref={innerRef} {...props}>
      {children}
    </Text>
  );
}

export { _Text as Text };
